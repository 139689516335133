<template>
  <div class="box">
    <div class="top">
      <div class="title"> {{ isEdit? '修改人员信息2':'录入人员信息2' }}</div>
    </div>
    <div class="innerBox">
      <el-form ref="form" :model="form"  :rules="rules" label-width="80px">
        <el-form-item label="姓名"  prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="form.sex">
            <el-radio label="女">女</el-radio>
            <el-radio label="男">男</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="类别" prop="type">
          <el-radio-group v-model="form.category">
            <el-radio label="食品">食品</el-radio>
            <el-radio label="药品">药品</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="编号" prop="number">
          <el-input v-model="form.number"></el-input>
        </el-form-item>
        <el-form-item label="证件号码" prop="idNumber">
          <el-input v-model="form.idNumber"></el-input>
        </el-form-item>
        <el-form-item label="照片编号" prop="pictureNumber">
          <el-input v-model="form.pictureNumber"></el-input>
        </el-form-item>
        <el-form-item label="徽章编号" prop="imgType">
          <el-input v-model="form.imgType"></el-input>
        </el-form-item>
        <el-form-item label="照片" prop="type">
          <div style="position: relative;width: 100px;">
            <img class="img" v-show="!picUrl" src="@/assets/+.png" alt="">
            <input
              id="upload_photo"
              type="file"
              accept="image/*"
              value=""
              class="upload"
              @change="upload($event)"
              v-show="!picUrl"
            >
            <div class="delete" v-show="picUrl" @click="deleteImg">x</div>
            <img class="img" v-show="picUrl" :src="picUrl" alt="">
          </div>
        </el-form-item>
        <el-form-item label="时间" prop="dataTime">
          <!-- <el-col :span="11"> -->
            <el-date-picker type="date"
              placeholder="选择日期"
              v-model="form.dataTime"
              style="width: 100%;"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          <!-- </el-col> -->
          <!-- <el-col class="line" :span="2">-</el-col>
          <el-col :span="11">
            <el-time-picker placeholder="选择时间" v-model="form.date" style="width: 100%;"></el-time-picker>
          </el-col> -->
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('form')">{{ isEdit? '立即修改':'立即创建' }}</el-button>
        </el-form-item>
      </el-form>

    </div>

    <!-- <div class="selectBox">
      <div v-for="(item,index) in list2" :key="index" class="select">
        <div class="selectText">
          <div>{{ index+1 }}、</div>
          <div>故障现象</div>
          <div>{{ index+1 }}</div>
        </div>
        <el-select v-model="item.content" filterable multiple placeholder="请选择">
        <el-option 
          v-for="item in options"
          :key="item.label"
          :label="item.label"
          :value="item.label">
        </el-option>
      </el-select>
      </div>
    </div> -->
  </div>
</template>

<script>
import { submit2, getData2 } from '@/api/index2.js'
export default {
  components: {},
  props: {},
  data() {
    return {
      form: {
        name: '',
        sex: '',
        number: '',
        idNumber: '',
        pictureNumber: '',
        imgType: '',
        dataTime: '',
        file: '',
        category: '',
      },
      table:{},
      picUrl: '',
      isEdit: false,
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        sex: [
          { required: true, message: '请选择性别', trigger: 'change' }
        ],
        number: [
          { required: true, message: '请输入编号', trigger: 'blur' }
        ],
        category: [
          { required: true, message: '请输入类型', trigger: 'change' }
        ],
        idNumber: [
          { required: true, message: '请输入证件号', trigger: 'change' } 
        ],
        // pictureNumber: [
        //   { required: true, message: '请输入照片编号', trigger: 'change' }
        // ],
          // imgType: [
          //   { required: true, message: '请输入徽章编号', trigger: 'change' }
          // ],
        dataTime: [
          { required: true, message: '请选择时间', trigger: 'blur' }
        ],
      }
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    console.log('this',this.$route.query.id)
    if (this.$route.query.id) {
      this.isEdit = true
      getData2(this.$route.query.id).then(res => {
        this.form = res.data
      })
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.isEdit) {
            let formData = new FormData()
            formData.append('name', this.form.name)
            formData.append('sex', this.form.sex )
            formData.append('number', this.form.number )
            formData.append('idNumber', this.form.idNumber )
            formData.append('pictureNumber', this.form.pictureNumber )
            formData.append('imgType', this.form.imgType )
            formData.append('dataTime', this.form.dataTime )
            formData.append('file', this.form.file)
            formData.append('category', this.form.category)
            console.log('formData', formData)
            submit2(formData).then(res => {
              console.log('sub', res)
              this.$notify({
                title: '添加成功',
                message: `成功添加${this.form.name}信息`,
                type: 'success'
              });
            })
          } else {
            let formData = new FormData()
            formData.append('id', this.$route.query.id)
            formData.append('name', this.form.name)
            formData.append('sex', this.form.sex )
            formData.append('number', this.form.number )
            formData.append('idNumber', this.form.idNumber )
            formData.append('pictureNumber', this.form.pictureNumber )
            formData.append('imgType', this.form.imgType )
            formData.append('dataTime', this.form.dataTime )
            formData.append('file', this.form.file)
            formData.append('category', this.form.category)
            console.log('formData', formData)
            submit2(formData).then(res => {
              console.log('sub', res)
              this.$notify({
                title: '修改成功',
                message: `成功修改${this.form.name}信息`,
                type: 'success'
              });
            })
          }
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    upload(el) {
      console.log('upload')
      if (!el.target.files[0].size) return
      let imgFile = el.target.files[0]
      console.log('imgFile', imgFile)
      // _this.imgL.push({ obj })
      // _this.imgList.push({ file })
      this.form.file = imgFile
      this.picUrl = URL.createObjectURL(imgFile)
    },
    deleteImg() {
      this.picUrl = ''
      this.form.file = ''
    },
    // put() {
    //   // sessionStorage.setItem('list1', JSON.stringify(this.list1))
    //   // sessionStorage.setItem('list2', JSON.stringify(this.list2))
    //   let list = [...this.list1, ...this.list2]
    //   console.log('list', list)
    //   sessionStorage.setItem('list', JSON.stringify(list))
    //   this.$router.push({
    //     path: '/diagnosis'
    //   })
    // }
  }
}
</script>
<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.box {
  min-height: 90vh;
  width: 98%;
  margin: 1%;
  padding: 1% 2%;
  background-color: #fff;
}
.innerBox {
  margin:  60px auto 0;
  width: 50%;
}
.el-button--primary {
    color: #FFF;
    background-color: #189F92;
    border-color: #189F92;
}
// margin-top: 100px;
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  .title {
    padding-left: 10px;
    border-left: 4px solid #189F92;
  }
  .addBtn {
    padding: 5px 20px;
    border: 1px solid #189F92;
    border-radius: 5px;
    color: #189F92;
    cursor: pointer;
  }
}
.btn {
  width: fit-content;
  margin: 20px auto;
  padding: 7px 50px;
  background-color: #189F92;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}
.selectBox {
  width: 100%;
  padding: 20px 100px;
}
.select {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.selectText {
  display: flex;
  align-items: center;
  margin-right: 100px;
}
.img {
  width: 100px;
  height: 100px;
}
.upload {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 9;
  cursor: pointer;
}
.delete {
  color: red;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 120%;
  transform: translate(-50%,-50%);
  cursor: pointer;
}
</style>
