import server from "@/axios/index.js";
// import qs from 'qs'

export function rental2(data) {
  return server.request({
    method: "get",
    headers: {
      'Content-Type': 'application/json'
    },
    url: "/api/cp_rental/get",
    params: data,
  });
}
export function switch2(data) {
  return server.request({
    method: "post",
    headers: {
      'Content-Type': 'application/json'
    },
    url: "/api/cp_rental/switch",
    data: data,
  });
}

export function submit2(data) {
  return server.request({
    method: "post",
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url: "/api/cp_rental/submit",
    data: data,
  });
}

export function deleteID2(id) {
  return server.request({
    method: "delete",
    url: `/api/cp_rental/delete/${id}`,
  });
}

export function getData2(id) {
  return server.request({
    method: "get",
    url: `/api/cp_rental/get/${id}`,
  });
}


